import React, {FunctionComponent, useContext, useState} from "react";
import { Button, Container } from "react-bootstrap";
import { PortableText, SanityColorList, ImageInterface } from "../../types/SanityTypes";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import BlockContent from "@sanity/block-content-to-react";
import { ColorVariations } from "web-common/src/types/ComponentTypes";
import "./styles.scss";
import { CSSTransition } from "react-transition-group";
import { graphql, useStaticQuery } from "gatsby";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { Link } from "../Link";
import {LocalizedContext} from "../../services/LocalizedContextService";

interface FeaturedCarouselInterface {
  name: string;
  headline: {
    primaryText: string;
    secondaryText: string;
  };
  theme: {
    name: string;
  };
  slides: TSlide[];
}

interface TSlide {
  itemName: string;
  _rawItemDescription: PortableText;
  ctaReference: {
    _type: string;
    slug: {
      current: string;
    };
  };
  ctaLabel: string;
  bgColor: SanityColorList | null;
  _rawPrimaryImage: ImageInterface;
  _rawSecondaryImage: ImageInterface | null;
}

const FeaturedCarousel: FunctionComponent<FeaturedCarouselInterface> = ({ name, headline, theme, slides }) => {
  const [selectedSlide, setSelectedSlide] = useState<string>(slides[0].itemName);
  const landingPageData = useStaticQuery(graphql`
    query {
      allSanityProductLandingPageV2 {
        nodes {
          market
          slug {
            current
          }
        }
      }
    }
  `);

  const { market } = useContext(LocalizedContext);

  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({ projectId: sanityId, dataset: sanityDataset });

  const filteredLandingPageData = landingPageData.allSanityProductLandingPageV2.nodes.filter((node: any) => node.market === market);

  const landingPageSlug = filteredLandingPageData[0]?.slug?.current || "/";

  function createThemeClassName(className: string | undefined) {
    return className ? `theme--${className.replace(/\s+/g, "").toLowerCase()}` : "";
  }

  function handleCTALink(selectedSlide: TSlide) {
    const ctaReference = selectedSlide.ctaReference;
    const sectionPageSlug = ctaReference?._type === "sectionPage" ? ctaReference?.slug.current : "";
    return sectionPageSlug ? sectionPageSlug : `${landingPageSlug}/${ctaReference?.slug.current}`;
  }

  function renderButton(slide: TSlide) {
    const isSelected = selectedSlide === slide.itemName;
    return (
      <Button
        key={slide.itemName}
        className={`slide-btn btn btn-primary ${isSelected ? "selected" : ""}`}
        onClick={() => setSelectedSlide(slide.itemName)}
      >
        {slide.itemName}
      </Button>
    );
  }

  function renderItem(slide: TSlide) {
    const isItemSelected = selectedSlide === slide.itemName;
    return (
      <CSSTransition key={slide.itemName} classNames="item" in={isItemSelected} timeout={300}>
        <div className={`featured-carousel-item ${isItemSelected ? "selected" : ""}`}>
          <div className="slide">
            <BlockContent blocks={slide._rawItemDescription} />
          </div>
          <Link className={`cta-button`} to={handleCTALink(slide)}>
            {slide.ctaLabel}
          </Link>
        </div>
      </CSSTransition>
    );
  }

  function renderImage(slide: TSlide) {
    const isItemSelected = selectedSlide === slide.itemName;

    const createUrlBuilder = (image: ImageInterface, width: number, height: number) => {
      return urlBuilder
        .image(image)
        .auto("format")
        .quality(80)
        .width(width)
        .height(height)
        .fit("scale")
        .format("webp")
        .url();
    };

    const colorVariations: ColorVariations = {
      Purple: {
        primaryGradientColor: "var(--purple-light-color)",
        secondaryGradientColor: "var(--pink-light-color)",
        gradientDegrees: "45deg"
      },
      Red: {
        primaryGradientColor: "var(--orange-color)",
        secondaryGradientColor: "var(--pink-medium-color)",
        gradientDegrees: "300deg"
      },
      Teal: {
        primaryGradientColor: "var(--get-mentored-green-color)",
        secondaryGradientColor: "var(--get-mentored-blue-color)",
        gradientDegrees: "225deg"
      },
      Pink: {
        primaryGradientColor: "var(--pink-bright-color)",
        secondaryGradientColor: "var(--pink-bright-color)",
        gradientDegrees: "-146deg"
      },
      Blue: {
        primaryGradientColor: "var(--blue-dark-color)",
        secondaryGradientColor: "var(--blue-bright-color)",
        gradientDegrees: "90deg"
      },
      Primary: {
        primaryGradientColor: "var(--primary-color)",
        secondaryGradientColor: "var(--primary-color)",
        gradientDegrees: "90deg"
      },
      Default: {
        primaryGradientColor: "var(--pink-color)",
        secondaryGradientColor: "var(--purple-color)",
        gradientDegrees: "75deg"
      }
    };

    const { primaryGradientColor, secondaryGradientColor, gradientDegrees } =
      colorVariations[slide.bgColor?.title || "Default"];

    const gradientStyle = `linear-gradient(${gradientDegrees}, ${primaryGradientColor}, ${secondaryGradientColor})`;

    return (
      <div
        key={slide.itemName}
        className={`${slide._rawSecondaryImage ? "featured-carousel-double-image" : "featured-carousel-image"} ${
          isItemSelected ? "selected" : ""
        }`}
        aria-hidden={!isItemSelected}
      >
        <picture data-testid="feature-carousel-image" className="primary-image">
          <img
            src={createUrlBuilder(
              slide._rawPrimaryImage,
              slide._rawSecondaryImage ? 420 : 500,
              slide._rawSecondaryImage ? 600 : 490
            )}
            loading={"lazy"}
            alt={slide._rawPrimaryImage.alt}
            className="img-fluid"
          />
        </picture>
        {slide._rawSecondaryImage && (
          <picture className="secondary-image">
            <img
              className="img-fluid"
              width="330"
              height="265"
              loading={"lazy"}
              src={createUrlBuilder(slide._rawSecondaryImage, 330, 265)}
              alt={slide._rawPrimaryImage.alt}
            />
          </picture>
        )}
        {slide._rawSecondaryImage && <div className="colored-square" style={{ background: gradientStyle }}></div>}
      </div>
    );
  }

  return (
    <section className={`featured-carousel carousel-${name} ${createThemeClassName(theme?.name)}`}>
      <Container fluid className="carousel-container">
        <div className="featured-carousel-block">
          <div className="carousel-content">
            <h2>
              {headline.primaryText}
              <span className="secondary-heading">{headline.secondaryText}</span>
            </h2>
            <div className="carousel-btn">{slides.map(renderButton)}</div>
            <div className="item-description">{slides.map(renderItem)}</div>
          </div>
          <div className="carousel-image-container">{slides.map(renderImage)}</div>
        </div>
      </Container>
    </section>
  );
};

export default FeaturedCarousel;
